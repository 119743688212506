import { AllocationExecutionStatus } from "../generated/graphql";

export const ALLOCATION_EXECUTION_TERMINAL_STATES = [
  AllocationExecutionStatus.Completed,
  AllocationExecutionStatus.Failed,
  AllocationExecutionStatus.Cancelled,
  AllocationExecutionStatus.Invalidated,
];

// If the withdrawal amount is greater than 80% of the allocation value,
// we will fully liquidate the allocation.
export const ALLOCATION_FULL_LIQUIDATION_THRESHOLD = 0.8;
