import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common/enums";
import {
  ACH_DAILY_TRANSFER_LIMIT,
  ACH_DEPOSIT_MIN,
  WIRE_WITHDRAWAL_MIN,
} from "../constants/cashTransfer";
import { DI_SUPPORTED_LIQUIDATION_DESTINATIONS } from "../constants/directIndex";
import {
  CashTransferDirection,
  CashTransferMethod,
  MoneyMovementSourceType,
  MoneyMovementSourceTypeGroup,
} from "../generated/graphql";
import { MONEY_MOVEMENT_SOURCE_GROUP_MAP } from "../portfolio_utils";
import { formatUsdCompressed } from "../utils";
import { MIN_LOAN_ADDITION } from "../constants";

export enum CreateCashTransferValidationResult {
  InsufficientCash = "InsufficientCash",
  InsufficientBorrowingPower = "InsufficientBorrowingPower",
  LoanAmountShouldBeGreaterThanMin = "LoanAmountShouldBeGreaterThanMin",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  AchDepositAmountShouldBeGreaterThanMin = "AchDepositAmountShouldBeGreaterThanMin",
  WireWithdrawalAmountShouldBeGreaterThanMin = "WireWithdrawalAmountShouldBeGreaterThanMin",
  NotAllowed = "NotAllowed",
  AchDailyLimitBreached = "AchDailyLimitBreached",
  InvalidWireRoutingNumber = "InvalidWireRoutingNumber",
  UnsupportedCashTransferMethod = "UnsupportedCashTransferMethod",
  UnsupportedCashTransferAccountDirection = "UnsupportedCashTransferAccountDirection",
  UnsupportedFullLiquidation = "UnsupportedFullLiquidation",
}

export const CreateCashTransferValidationErrorMessage: Record<
  CreateCashTransferValidationResult,
  string
> = {
  [CreateCashTransferValidationResult.InsufficientCash]:
    "This exceeds the amount that is available to withdraw in your account",
  [CreateCashTransferValidationResult.InsufficientBorrowingPower]:
    "This exceeds the amount that is available to borrow in your account",
  [CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin]: `The minimum incremental loan amount is ${formatUsdCompressed(
    MIN_LOAN_ADDITION,
  )}`,
  [CreateCashTransferValidationResult.AmountShouldBeGreaterThanZero]:
    "Amount should be greater than $0",
  [CreateCashTransferValidationResult.AchDepositAmountShouldBeGreaterThanMin]: `The minimum amount to deposit is ${formatUsdCompressed(
    ACH_DEPOSIT_MIN,
  )}`,
  [CreateCashTransferValidationResult.WireWithdrawalAmountShouldBeGreaterThanMin]: `The minimum amount to withdraw is ${formatUsdCompressed(
    WIRE_WITHDRAWAL_MIN.toNumber(),
  )}`,
  [CreateCashTransferValidationResult.NotAllowed]:
    "You are not allowed to perform this transfer",
  [CreateCashTransferValidationResult.AchDailyLimitBreached]: `This exceeds the daily limit for ACH transfers. You can wire instead.`,
  [CreateCashTransferValidationResult.InvalidWireRoutingNumber]:
    "Invalid wire routing number. Please select another account",
  [CreateCashTransferValidationResult.UnsupportedCashTransferMethod]:
    "Invalid cash transfer option",
  [CreateCashTransferValidationResult.UnsupportedCashTransferAccountDirection]:
    "We currently don’t support transferring between these accounts",
  [CreateCashTransferValidationResult.UnsupportedFullLiquidation]:
    "Full liquidation is only supported for Direct Index, Treasury, and Allocations",
};

export const CreateCashTransferValidationResultLevelMap: Record<
  CreateCashTransferValidationResult,
  ValidationResultLevel
> = {
  InsufficientCash: ValidationResultLevel.Error,
  InsufficientBorrowingPower: ValidationResultLevel.Error,
  LoanAmountShouldBeGreaterThanMin: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
  AchDepositAmountShouldBeGreaterThanMin: ValidationResultLevel.Error,
  WireWithdrawalAmountShouldBeGreaterThanMin: ValidationResultLevel.Error,
  NotAllowed: ValidationResultLevel.Error,
  AchDailyLimitBreached: ValidationResultLevel.Error,
  InvalidWireRoutingNumber: ValidationResultLevel.Error,
  UnsupportedCashTransferMethod: ValidationResultLevel.Warning,
  UnsupportedCashTransferAccountDirection: ValidationResultLevel.Error,
  UnsupportedFullLiquidation: ValidationResultLevel.Error,
};

export type CreateExternalCashTransferArgs = {
  amount: Decimal;
  method: CashTransferMethod;
  direction: CashTransferDirection;
  amountAvailableToWithdraw: Decimal;
  isAllowed: boolean;
  isClientAccount?: boolean;
  currentDayAchDepositTotal: Decimal;
  currentDayAchWithdrawalTotal: Decimal;
  wireRoutingNumber?: string;
  isForBorrow: boolean;
  isQueued?: boolean;
  achDailyTransferLimitOverride?: number;
  bypassMinDepositCheck?: boolean;
};

export type CreateCashTransferArgs = {
  fromType: MoneyMovementSourceType;
  toType: MoneyMovementSourceType;
  amount: Decimal;
  amountAvailableToWithdraw: Decimal;
  isAllowed: boolean;
  isClientAccount?: boolean;
  currentDayAchDepositTotal: Decimal;
  currentDayAchWithdrawalTotal: Decimal;
  wireRoutingNumber?: string;
  isForBorrow: boolean;
  isFullLiquidation?: boolean;
  achDailyTransferLimitOverride?: number;
  isQueued?: boolean;
};

export const VALID_TRANSFER_DIRECTIONS = new Map([
  [
    MoneyMovementSourceType.Ach,
    new Set([
      MoneyMovementSourceType.FrecCash,
      MoneyMovementSourceType.DirectIndex,
      MoneyMovementSourceType.Treasury,
      MoneyMovementSourceType.Allocation,
    ]),
  ],
  [
    MoneyMovementSourceType.Wire,
    new Set([
      MoneyMovementSourceType.FrecCash,
      MoneyMovementSourceType.DirectIndex,
      MoneyMovementSourceType.Treasury,
    ]),
  ],
  [
    MoneyMovementSourceType.FrecCash,
    new Set([
      MoneyMovementSourceType.Ach,
      MoneyMovementSourceType.Wire,
      MoneyMovementSourceType.DirectIndex,
      MoneyMovementSourceType.Treasury,
      MoneyMovementSourceType.Check,
      MoneyMovementSourceType.Allocation,
    ]),
  ],
  [
    MoneyMovementSourceType.Treasury,
    new Set([
      MoneyMovementSourceType.Allocation,
      MoneyMovementSourceType.DirectIndex,
      MoneyMovementSourceType.FrecCash,
      MoneyMovementSourceType.Ach,
      MoneyMovementSourceType.Wire,
      MoneyMovementSourceType.Allocation,
    ]),
  ],
  [MoneyMovementSourceType.DirectIndex, DI_SUPPORTED_LIQUIDATION_DESTINATIONS],
  // Check doesn't support deposits
  [MoneyMovementSourceType.Check, new Set([])],
  // PLOC can only be transferred to DI and Allocation via transfer flow
  [
    MoneyMovementSourceType.LineOfCredit,
    new Set([
      MoneyMovementSourceType.DirectIndex,
      MoneyMovementSourceType.Allocation,
    ]),
  ],
  [
    MoneyMovementSourceType.Allocation,
    new Set([
      MoneyMovementSourceType.DirectIndex, // only if DI is not part of allocation
      MoneyMovementSourceType.FrecCash,
      MoneyMovementSourceType.Ach,
      MoneyMovementSourceType.Wire,
      MoneyMovementSourceType.Treasury, // only if treasury is not part of allocation
    ]),
  ],
]);

/**
 * Used for all cash transfer flows (intra + external)
 * No need for additional validation for Allocation since allocation will
 * internally do multiple transfers based on the config.
 */
export const validateCashTransfer = (args: CreateCashTransferArgs) => {
  const results = new Set<CreateCashTransferValidationResult>();

  if (!args.isAllowed)
    results.add(CreateCashTransferValidationResult.NotAllowed);

  if (args.amount.lessThanOrEqualTo(0)) {
    results.add(
      CreateCashTransferValidationResult.AmountShouldBeGreaterThanZero,
    );
  }

  const fromMoneyMovementGroup = MONEY_MOVEMENT_SOURCE_GROUP_MAP[args.fromType];
  const toMoneyMovementGroup = MONEY_MOVEMENT_SOURCE_GROUP_MAP[args.toType];

  const validToTypes = VALID_TRANSFER_DIRECTIONS.get(args.fromType);
  if (!validToTypes || !validToTypes.has(args.toType)) {
    results.add(
      CreateCashTransferValidationResult.UnsupportedCashTransferAccountDirection,
    );
  }

  if (
    args.fromType === MoneyMovementSourceType.LineOfCredit &&
    args.amount.gt(args.amountAvailableToWithdraw)
  ) {
    results.add(CreateCashTransferValidationResult.InsufficientBorrowingPower);
  }

  if (
    args.fromType === MoneyMovementSourceType.LineOfCredit &&
    args.amount.lt(MIN_LOAN_ADDITION)
  ) {
    results.add(
      CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin,
    );
  }

  // Check the balance when transferring from an internal account
  if (
    !args.isForBorrow &&
    !args.isQueued &&
    (fromMoneyMovementGroup === MoneyMovementSourceTypeGroup.SubAccount ||
      fromMoneyMovementGroup === MoneyMovementSourceTypeGroup.Allocation) &&
    args.amount.greaterThan(args.amountAvailableToWithdraw)
  ) {
    results.add(CreateCashTransferValidationResult.InsufficientCash);
  }

  if (
    args.isFullLiquidation &&
    args.fromType !== MoneyMovementSourceType.DirectIndex &&
    args.fromType !== MoneyMovementSourceType.Allocation &&
    args.fromType !== MoneyMovementSourceType.Treasury
  ) {
    results.add(CreateCashTransferValidationResult.UnsupportedFullLiquidation);
  }

  if (fromMoneyMovementGroup === MoneyMovementSourceTypeGroup.DepositAccount) {
    validateExternalCashTransfer({
      ...args,
      isQueued: args.isQueued ?? false,
      direction: CashTransferDirection.Deposit,
      method:
        args.fromType === MoneyMovementSourceType.Ach
          ? CashTransferMethod.Ach
          : args.fromType === MoneyMovementSourceType.Wire
            ? CashTransferMethod.Wire
            : CashTransferMethod.Check,
    }).forEach((o) => results.add(o));
  } else if (
    toMoneyMovementGroup === MoneyMovementSourceTypeGroup.DepositAccount
  ) {
    validateExternalCashTransfer({
      ...args,
      isQueued:
        args.isQueued ?? args.fromType === MoneyMovementSourceType.Treasury,
      direction: CashTransferDirection.Withdrawal,
      method:
        args.toType === MoneyMovementSourceType.Ach
          ? CashTransferMethod.Ach
          : args.toType === MoneyMovementSourceType.Wire
            ? CashTransferMethod.Wire
            : CashTransferMethod.Check,
    }).forEach((o) => results.add(o));
  }

  return Array.from(results);
};

/**
 * Used for cash transfer involving deposit account (ach/wire/cheque) for moving money in and out of frec
 */
export const validateExternalCashTransfer = (
  args: CreateExternalCashTransferArgs,
) => {
  const results = new Set<CreateCashTransferValidationResult>();

  if (!args.isAllowed)
    results.add(CreateCashTransferValidationResult.NotAllowed);

  if (args.amount.lessThanOrEqualTo(0)) {
    results.add(
      CreateCashTransferValidationResult.AmountShouldBeGreaterThanZero,
    );
  }

  if (args.method === CashTransferMethod.Ach) {
    validateAch(args).forEach((o) => results.add(o));
  } else if (args.method === CashTransferMethod.Wire) {
    validateWire(args).forEach((o) => results.add(o));
  } else if (args.method === CashTransferMethod.Check) {
    validateCheck(args).forEach((o) => results.add(o));
  } else {
    results.add(
      CreateCashTransferValidationResult.UnsupportedCashTransferMethod,
    );
  }

  if (
    !args.isForBorrow &&
    !args.isQueued &&
    args.direction === CashTransferDirection.Withdrawal &&
    args.amount.greaterThan(args.amountAvailableToWithdraw)
  ) {
    results.add(CreateCashTransferValidationResult.InsufficientCash);
  }

  return Array.from(results);
};

const validateAch = (args: CreateExternalCashTransferArgs) => {
  const results = new Set<CreateCashTransferValidationResult>();

  if (
    args.direction === CashTransferDirection.Deposit &&
    args.amount.lessThan(ACH_DEPOSIT_MIN) &&
    !args.bypassMinDepositCheck
  ) {
    results.add(
      CreateCashTransferValidationResult.AchDepositAmountShouldBeGreaterThanMin,
    );
  }

  if (
    args.direction === CashTransferDirection.Deposit &&
    args.currentDayAchDepositTotal
      .plus(args.amount)
      .greaterThan(
        args.achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
      )
  ) {
    results.add(CreateCashTransferValidationResult.AchDailyLimitBreached);
  }

  if (
    args.direction === CashTransferDirection.Withdrawal &&
    args.currentDayAchWithdrawalTotal
      .plus(args.amount)
      .greaterThan(
        args.achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
      )
  ) {
    results.add(CreateCashTransferValidationResult.AchDailyLimitBreached);
  }

  return results;
};

const validateWire = (args: CreateExternalCashTransferArgs) => {
  const results = new Set<CreateCashTransferValidationResult>();

  if (args.direction === CashTransferDirection.Withdrawal) {
    if (args.amount.lessThan(WIRE_WITHDRAWAL_MIN)) {
      results.add(
        CreateCashTransferValidationResult.WireWithdrawalAmountShouldBeGreaterThanMin,
      );
    }

    if (args.wireRoutingNumber === undefined) {
      results.add(CreateCashTransferValidationResult.InvalidWireRoutingNumber);
    }
  }

  return results;
};

const validateCheck = (args: CreateExternalCashTransferArgs) => {
  const results = new Set<CreateCashTransferValidationResult>();

  if (args.direction === CashTransferDirection.Deposit) {
    results.add(CreateCashTransferValidationResult.NotAllowed);
  }

  return results;
};
