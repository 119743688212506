import {
  DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP,
  normalizeAllocationConfig,
  validateAllocationConfig,
  ValidateAllocationConfigResult,
} from "@frec-js/common";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AllocationConfigCashTransferInput,
  AllocationConfigPurchaseOrderInput,
  AllocationConfigType,
  ClearingAccountFragment,
  SelfManagedTaxLossConfig,
  SubAccountType,
} from "../../generated/graphql";
import {
  CurrentSubAccountAllocationsMap,
  useCurrentSubAccountAllocations,
} from "../../hooks";
import {
  DirectIndexingDividendPreferenceType,
  SelfManagedDividendPreferenceType,
} from "../../types";

export interface AllocationSetupProviderContextProps {
  loading: boolean;
  // SetupLayout
  stepReached: number;
  setStepReached: Dispatch<SetStateAction<number>>;
  // Allocation
  type: AllocationConfigType;
  setType: Dispatch<SetStateAction<AllocationConfigType>>;
  currentSubAccountAllocations: CurrentSubAccountAllocationsMap;
  cashTransfers: AllocationConfigCashTransferInput[];
  addCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  removeCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  editCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  // TODO: implement purchase orders
  purchaseOrders: AllocationConfigPurchaseOrderInput[];
  // Recurring deposit
  skipRecurringDeposit: boolean;
  setSkipRecurringDeposit: Dispatch<SetStateAction<boolean>>;
  // Dividend preferences
  disableTreasuryOption: boolean;
  directIndexingDividendPreference: DirectIndexingDividendPreferenceType;
  setDirectIndexingDividendPreference: Dispatch<
    SetStateAction<DirectIndexingDividendPreferenceType>
  >;
  primaryDividendPreference: SelfManagedDividendPreferenceType;
  setPrimaryDividendPreference: Dispatch<
    SetStateAction<SelfManagedDividendPreferenceType>
  >;
  // Auto conversion
  sweepToSubAccountId: string | undefined;
  autoConversionHidden: boolean;
  autoConversionSecurities: string[];
  toggleAutoConversionSecurity: (securityId: string) => void;
  sweepAll: boolean;
  setSweepAll: Dispatch<SetStateAction<boolean>>;
  // Utils
  normalize: () => void;
  // Validation
  validationErrors: ValidateAllocationConfigResult;
}

const AllocationSetupContext =
  createContext<AllocationSetupProviderContextProps>({
    loading: false,
    // SetupLayout
    stepReached: 0,
    setStepReached: () => undefined,
    // Allocation
    type: AllocationConfigType.PortfolioRebalance, // Only use PortfolioRebalance for Portfolio Allocations
    setType: () => undefined,
    currentSubAccountAllocations: new Map(),
    cashTransfers: [],
    addCashTransfer: () => undefined,
    removeCashTransfer: () => undefined,
    editCashTransfer: () => undefined,
    // TODO: implement purchase orders
    purchaseOrders: [],
    // Recurring deposit
    skipRecurringDeposit: false,
    setSkipRecurringDeposit: () => undefined,
    // Dividend preferences
    disableTreasuryOption: false,
    directIndexingDividendPreference: DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
      SubAccountType.DirectIndex
    ] as DirectIndexingDividendPreferenceType,
    setDirectIndexingDividendPreference: () => undefined,
    primaryDividendPreference: DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
      SubAccountType.Primary
    ] as SelfManagedDividendPreferenceType,
    setPrimaryDividendPreference: () => undefined,
    // Auto conversion
    sweepToSubAccountId: undefined,
    autoConversionHidden: true, // Hidden by default
    autoConversionSecurities: [],
    toggleAutoConversionSecurity: () => undefined,
    sweepAll: false,
    setSweepAll: () => undefined,
    // Utils
    normalize: () => undefined,
    // Validation
    validationErrors: {
      allocationConfigErrors: [],
    },
  });

export const useAllocationSetup = () => {
  const context = useContext<AllocationSetupProviderContextProps>(
    AllocationSetupContext,
  );
  return context;
};

type AllocationSetupProviderProps = PropsWithChildren<{
  // Use conditional rendering so this provider is not mounted when the clearing account is undefined.
  clearingAccount?: ClearingAccountFragment;
  // Although this is optional (if the config does not exist), it should be passed as a defined value
  // (if the config exists) when this provider is mounted.
  selfManagedTaxLossConfig?: SelfManagedTaxLossConfig;
}>;

/**
 * Context provider for the Allocations set up flow.
 * Consumers should read the `loading` state to determine if the provider is ready.
 * This provider loads sub account portfolio aggregates once and then uses the data to set the initial state.
 */
export const AllocationSetupProvider = ({
  clearingAccount,
  selfManagedTaxLossConfig,
  children,
}: AllocationSetupProviderProps) => {
  const prefillPortfolioAllocation =
    useRef<AllocationConfigCashTransferInput[]>();
  const { currentSubAccountAllocations, loading } =
    useCurrentSubAccountAllocations(clearingAccount?.id);
  // Include all direct indices and treasury accounts by default

  // Provider states
  const [stepReached, setStepReached] = useState<number>(0);
  const [type, setType] = useState<AllocationConfigType>(
    AllocationConfigType.PortfolioRebalance,
  );
  const [cashTransfers, setCashTransfers] = useState<
    AllocationConfigCashTransferInput[]
  >([]);
  const [skipRecurringDeposit, setSkipRecurringDeposit] =
    useState<boolean>(false);
  const [
    directIndexingDividendPreference,
    setDirectIndexingDividendPreference,
  ] = useState<DirectIndexingDividendPreferenceType>(
    (clearingAccount?.dividendPreference?.find(
      (d) => d.subAccountType === SubAccountType.DirectIndex,
    )?.preferenceType ??
      DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
        SubAccountType.DirectIndex
      ]) as DirectIndexingDividendPreferenceType,
  );
  const [primaryDividendPreference, setPrimaryDividendPreference] =
    useState<SelfManagedDividendPreferenceType>(
      (clearingAccount?.dividendPreference?.find(
        (d) => d.subAccountType === SubAccountType.Primary,
      )?.preferenceType ??
        DIVIDEND_PREFERENCE_DEFAULT_SETTINGS_MAP[
          SubAccountType.Primary
        ]) as SelfManagedDividendPreferenceType,
    );
  const [autoConversionSecurities, setAutoConversionSecurities] = useState<
    string[]
  >(selfManagedTaxLossConfig?.securities ?? []);
  const [sweepAll, setSweepAll] = useState<boolean>(
    selfManagedTaxLossConfig?.sweepAll ?? false,
  );

  // Initialize cash transfers from sub account allocations
  useEffect(() => {
    if (
      loading ||
      prefillPortfolioAllocation.current ||
      currentSubAccountAllocations.size === 0
    ) {
      return;
    }
    const initialCashTransfers: AllocationConfigCashTransferInput[] = [];
    // Only include sub accounts that have greater than 0% allocation, because
    // we only use integers for the percentage, we are filtering out small sub accounts.
    currentSubAccountAllocations.forEach((percentage, subAccountId) => {
      if (percentage.toDP(0).gt(0)) {
        initialCashTransfers.push({
          allocationSubAccountId: subAccountId,
          percentage: percentage.toDP(0),
        });
      }
    });
    // Normalize the cash transfers to ensure they sum to 100%
    const normalizedCashTransfers = normalizeAllocationConfig(
      {
        cashTransfers: initialCashTransfers,
        purchaseOrders: [],
        type: AllocationConfigType.PortfolioRebalance,
      },
      0,
    ).cashTransfers;
    setCashTransfers(normalizedCashTransfers);
    prefillPortfolioAllocation.current = normalizedCashTransfers;
  }, [currentSubAccountAllocations, loading]);

  // TODO: replace with reducer pattern
  const addCashTransfer = useCallback(
    (add: AllocationConfigCashTransferInput) => {
      // If the added sub account has a current allocation percentage,
      // use it from the currentCashTransfers ref.
      const currentPercentage = prefillPortfolioAllocation.current?.find(
        (c) => c.allocationSubAccountId === add.allocationSubAccountId,
      )?.percentage;
      if (currentPercentage?.gt(0)) {
        setCashTransfers((prev) => [
          ...prev,
          { ...add, percentage: currentPercentage.toDP(0) },
        ]);
      } else {
        setCashTransfers((prev) => [...prev, add]);
      }
    },
    [],
  );
  const removeCashTransfer = useCallback(
    (remove: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.filter(
          (c) => c.allocationSubAccountId !== remove.allocationSubAccountId,
        ),
      );
    },
    [],
  );
  const editCashTransfer = useCallback(
    (edit: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.map((c) =>
          c.allocationSubAccountId === edit.allocationSubAccountId ? edit : c,
        ),
      );
    },
    [],
  );

  const toggleAutoConversionSecurity = useCallback((securityId: string) => {
    setAutoConversionSecurities((prev) =>
      prev.includes(securityId)
        ? prev.filter((id) => id !== securityId)
        : [...prev, securityId],
    );
  }, []);

  const normalize = useCallback(() => {
    const normalized = normalizeAllocationConfig(
      {
        type,
        cashTransfers,
        // TODO: implement purchase orders
        purchaseOrders: [],
      },
      0,
    );
    setCashTransfers(normalized.cashTransfers);
  }, [type, cashTransfers]);

  const validationErrors = useMemo(() => {
    const errors = validateAllocationConfig({
      type,
      cashTransfers,
      purchaseOrders: [],
    });
    return errors;
  }, [type, cashTransfers]);

  // If the treasury is in the allocation, disable the reinvest into treasury option for dividends.
  const treasurySubAccountId = clearingAccount?.treasury?.subAccountId;
  const disableTreasuryOption = useMemo(() => {
    return Boolean(
      treasurySubAccountId &&
        cashTransfers.some(
          (transfer) =>
            transfer.allocationSubAccountId === treasurySubAccountId,
        ),
    );
  }, [treasurySubAccountId, cashTransfers]);

  const value = useMemo<AllocationSetupProviderContextProps>(() => {
    const sweepToSubAccountId =
      selfManagedTaxLossConfig?.sweepToSubAccountId ?? undefined;
    const autoConversionEnabled =
      selfManagedTaxLossConfig?.sweepAll ||
      (selfManagedTaxLossConfig?.securities?.length ?? 0) > 0;
    const autoConversionHidden =
      !autoConversionEnabled ||
      !cashTransfers.some(
        (ct) => ct.allocationSubAccountId === sweepToSubAccountId,
      );
    return {
      loading,
      // SetupLayout
      stepReached,
      setStepReached,
      // Allocation
      type,
      setType,
      currentSubAccountAllocations,
      cashTransfers,
      addCashTransfer,
      removeCashTransfer,
      editCashTransfer,
      // TODO: implement purchase orders
      purchaseOrders: [],
      // Recurring deposit
      skipRecurringDeposit,
      setSkipRecurringDeposit,
      // Dividend preferences
      disableTreasuryOption,
      directIndexingDividendPreference,
      setDirectIndexingDividendPreference,
      primaryDividendPreference,
      setPrimaryDividendPreference,
      // Auto conversion
      sweepToSubAccountId,
      autoConversionHidden,
      autoConversionSecurities,
      toggleAutoConversionSecurity,
      sweepAll,
      setSweepAll,
      // Utils
      normalize,
      // Validation
      validationErrors,
    };
  }, [
    loading,
    stepReached,
    setStepReached,
    type,
    setType,
    currentSubAccountAllocations,
    cashTransfers,
    addCashTransfer,
    removeCashTransfer,
    editCashTransfer,
    skipRecurringDeposit,
    setSkipRecurringDeposit,
    disableTreasuryOption,
    directIndexingDividendPreference,
    setDirectIndexingDividendPreference,
    primaryDividendPreference,
    setPrimaryDividendPreference,
    autoConversionSecurities,
    toggleAutoConversionSecurity,
    sweepAll,
    setSweepAll,
    normalize,
    validationErrors,
  ]);

  return (
    <AllocationSetupContext.Provider value={value}>
      {children}
    </AllocationSetupContext.Provider>
  );
};
