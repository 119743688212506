import Decimal from "decimal.js";
import { DateTime } from "luxon";
import { RefinementCtx, z } from "zod";

import { OTHER_RELATIONSHIP, RELATIONSHIP_OPTIONS } from "../../common";
import { CIP_CATEGORY_NAME_MAP, POBOX_REGEXP } from "../../constants";
import {
  AddressType,
  CipCategory,
  EmploymentStatus,
} from "../../generated/graphql";
import { normalizeName } from "../../utils";

export const EMAIL_SCHEMA = z.object({
  email: z
    .string()
    .min(5, { message: "Please provide a valid email" })
    .email({ message: "Please provide a valid email" })
    .max(320, { message: "Please provide a valid email" }),
});

export type EMAIL_SCHEMA_TYPE = z.infer<typeof EMAIL_SCHEMA>;

export const PASSWORD_SCHEMA = z.object({
  password: z
    .string()
    .min(8, { message: "Password should be longer than 8 characters" })
    .regex(/[A-Z]/, {
      message: "Password should contain at least an uppercase letter",
    })
    .regex(/[0-9]/, {
      message: "Password should contain at least one digit",
    }),
});
export type PASSWORD_SCHEMA_TYPE = z.infer<typeof PASSWORD_SCHEMA>;

export const SIGNIN_SCHEMA = EMAIL_SCHEMA.merge(
  z.object({
    password: z.string({ required_error: "Please provide a password" }),
  }),
);
export type SIGNIN_SCHEMA_TYPE = z.infer<typeof SIGNIN_SCHEMA>;

export const UPDATE_PASSWORD_SCHEMA = PASSWORD_SCHEMA.extend({
  confirmPassword: z.string({ required_error: "Please confirm your password" }),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"], // path of error
});
export type UPDATE_PASSWORD_SCHEMA_TYPE = z.infer<
  typeof UPDATE_PASSWORD_SCHEMA
>;

export const SIGNUP_SCHEMA = EMAIL_SCHEMA.merge(PASSWORD_SCHEMA);
export type SIGNUP_SCHEMA_TYPE = z.infer<typeof SIGNUP_SCHEMA>;

export const TAX_RATE_SCHEMA = z.object({
  federalIncomeTaxRate: z
    .instanceof(Decimal)
    .or(z.number())
    .refine((val) => val !== undefined, { message: "Required" })
    .refine((val) => (typeof val === "number" ? val >= 0 : val.gte(0)), {
      message: "Invalid value",
    })
    .refine((val) => (typeof val === "number" ? val <= 100 : val.lte(100)), {
      message: "Invalid value",
    }),
  federalCapitalGainsTaxRate: z.coerce // string
    .number()
    .refine((val) => val !== undefined, { message: "Required" }),
  stateCapitalGainsTaxRate: z
    .instanceof(Decimal)
    .or(z.number())
    .refine((val) => val !== undefined, { message: "Required" })
    .refine((val) => (typeof val === "number" ? val >= 0 : val.gte(0)), {
      message: "Invalid value",
    })
    .refine((val) => (typeof val === "number" ? val <= 100 : val.lte(100)), {
      message: "Invalid value",
    }),
});
export type TAX_RATE_SCHEMA_TYPE = z.infer<typeof TAX_RATE_SCHEMA>;

/**
 * APEX limits:
 * firstName: 20 characters
 * lastName: 20 characters
 * fullName/legalName: 30 characters
 *
 * Apex allow a leading zero in the first name for local testing environment
 */
export const NAME_SCHEMA = ({
  allowLeadingZero = false,
}: {
  allowLeadingZero?: boolean;
} = {}) =>
  z.object({
    firstName: z
      .string()
      .transform(normalizeName)
      .pipe(
        z
          .string()
          .min(1, { message: "First name is required" })
          .max(20, { message: "First name cannot exceed 20 characters" })
          .refine(
            (value) =>
              allowLeadingZero ? true : /^[A-Za-z\s\-']+$/.test(value),
            {
              message:
                "First name can only contain letters, spaces, hyphens and apostrophes",
            },
          ),
      ),
    lastName: z
      .string()
      .transform(normalizeName)
      .pipe(
        z
          .string()
          .min(1, { message: "Last name is required" })
          .max(20, { message: "Last name cannot exceed 20 characters" })
          .refine((value) => normalizeName(value).length > 0, {
            message: "Enter a valid last name",
          })
          .refine(
            (value) =>
              allowLeadingZero ? true : /^[A-Za-z\s\-']+$/.test(value),
            {
              message:
                "Last name can only contain letters, spaces, hyphens and apostrophes",
            },
          ),
      ),
  });

// TODO: get rid of this after Zod 4.0: https://github.com/colinhacks/zod/issues/2474
export const NAME_TOTAL_LENGTH_REFINE = (
  { firstName, lastName }: NAME_SCHEMA,
  ctx: RefinementCtx,
) => {
  const name = normalizeName(firstName) + " " + normalizeName(lastName);
  if (name.length > 30) {
    ctx.addIssue({
      code: z.ZodIssueCode.too_big,
      message:
        "Your full name cannot exceed 29 characters, you may have to abbreviate.",
      inclusive: true,
      type: "string",
      maximum: 30,
    });
  }
};

export type NAME_SCHEMA = z.infer<ReturnType<typeof NAME_SCHEMA>>;

export const SSN_SCHEMA = z.object({
  ssn: z
    .string()
    .min(1, { message: "SSN is required" })
    .regex(/^\d{3}-\d{2}-\d{4}$/, { message: "Please provide a valid SSN" }),
});
export type SSN_SCHEMA = z.infer<typeof SSN_SCHEMA>;

export const DATE_OF_BIRTH_SCHEMA = z.object({
  dateOfBirth: z
    .string()
    .min(1, { message: "Please provide a valid date of birth" })
    .refine(
      (value) => {
        if (!value) return false;
        const date = DateTime.fromFormat(value, "yyyy-MM-dd");
        if (!!date.invalidReason) return false;
        const age = DateTime.now().diff(date, "year").years;
        return age >= 18 && age <= 120;
      },
      (value) => {
        const date = DateTime.fromFormat(value, "yyyy-MM-dd");
        const age = DateTime.now().diff(date, "year").years;
        if (age < 18) {
          return { message: "You must be 18 or older to continue" };
        }
        if (age > 120) {
          return {
            message: `Are you sure you are ${DateTime.now()
              .diff(date, "year")
              .years.toFixed(0)} yrs old?`,
          };
        }
        return { message: "Please provide a valid date of birth" };
      },
    ),
});
export type DATE_OF_BIRTH_SCHEMA = z.infer<typeof DATE_OF_BIRTH_SCHEMA>;

export const BIRTHDAY_SCHEMA = z.object({
  day: z
    .string()
    .min(1, { message: "Valid day is required" })
    .refine(
      (value) => {
        const day = parseInt(value);
        return !Number.isNaN(day) && day > 0 && day <= 31;
      },
      { message: "Valid day is required" },
    ),
  month: z
    .string()
    .min(1, { message: "Valid month is required" })
    .refine(
      (value) => {
        const month = parseInt(value);
        return !Number.isNaN(month) && month > 0 && month <= 12;
      },
      { message: "Valid month is required" },
    ),
  year: z
    .string()
    .min(1, { message: "Valid year is required" })
    .refine(
      (value) => {
        const year = parseInt(value);
        if (Number.isNaN(year)) return false;
        if (year >= DateTime.now().year) return false;
        if (year > DateTime.now().year - 18) return false;
        if (year < DateTime.now().year - 120) return false;
        return true;
      },
      (value) => {
        const year = parseInt(value);
        if (Number.isNaN(year)) return { message: "Valid year is required" };
        if (year >= DateTime.now().year)
          return { message: "Valid year is required" };
        if (year > DateTime.now().year - 18)
          return { message: "You must be at least 18 years old" };
        if (year < DateTime.now().year - 120)
          return { message: "Valid year is required" };
        return { message: "Valid year is required" };
      },
    ),
});
export type BIRTHDAY_SCHEMA = z.infer<typeof BIRTHDAY_SCHEMA>;

export const ADDRESS_SCHEMA = (enforceTrustMaxStringLength?: boolean) =>
  enforceTrustMaxStringLength ? ADDRESS_SCHEMA_TRUST : ADDRESS_SCHEMA_BASE;

const ADDRESS_SCHEMA_BASE = z.object({
  addressLine1: z
    .string()
    .max(30)
    .min(1, { message: "Please enter a valid street address" })
    .refine((value) => !POBOX_REGEXP.test(value ?? ""), {
      message: "P.O. Box is not allowed",
    }),
  addressLine2: z
    .string()
    .max(30)
    .nullable()
    .optional()
    .refine((value) => !POBOX_REGEXP.test(value ?? ""), {
      message: "P.O. Box is not allowed",
    }),
  addressType: z.nativeEnum(AddressType).default(AddressType.Default),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  zipCode: z.string().min(1, { message: "Zip code is required" }),
  country: z
    .string()
    .default("USA")
    .refine(
      (v) => {
        const isUpperCase = v.toUpperCase() === v;
        const isLength3 = v.length === 3;
        const isOnlyLetters = /^[A-Z]+$/.test(v);
        return isLength3 && isUpperCase && isOnlyLetters;
      },
      { message: "Must be 3 character uppercase country code" },
    ),
});
export type ADDRESS_SCHEMA = z.infer<typeof ADDRESS_SCHEMA_BASE>;

const ADDRESS_SCHEMA_TRUST = ADDRESS_SCHEMA_BASE.superRefine((data, ctx) => {
  const combinedLength = `${data.addressLine1 ?? ""}, ${
    data.addressLine2 ?? ""
  }`.length;

  if (combinedLength > 30) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "Combined street address & apt/suite cannot exceed 30 characters",
      path: ["addressLine2"],
    });
  }
});
export type ADDRESS_SCHEMA_TRUST = z.infer<typeof ADDRESS_SCHEMA_TRUST>;

export const PHONE_SCHEMA = z.object({
  phone: z
    .string({
      required_error: "Please provide your phone number",
    })
    .regex(/^\(?([0-9]{3})\)?[ -.●]?([0-9]{3})[-.●]?([0-9]{4})$/, {
      message: "Please provide a valid phone number",
    }),
});
export type PHONE_SCHEMA = z.infer<typeof PHONE_SCHEMA>;

export const VERIFICATION_CODE_SCHEMA = z.object({
  code: z.string({
    required_error: "Please enter the verification code you received",
  }),
});
export type VERIFICATION_CODE_SCHEMA = z.infer<typeof VERIFICATION_CODE_SCHEMA>;

export const VISA_EXPIRATION_SCHEMA = z.object({
  visaExpiration: z
    .string({
      required_error: "Visa expiration date is required",
    })
    .superRefine((value, ctx) => {
      const expiry = DateTime.fromFormat(value, "yyyy-MM-dd");

      if (!expiry.isValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid date",
        });
        return;
      }

      if (expiry <= DateTime.now()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Visa expired already",
        });
        return;
      }

      const days90FromNow = DateTime.now().plus({ day: 90 });
      if (expiry <= days90FromNow) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Visa expiration should not be within 90 days",
        });
        return;
      }
    }),
});
export type VISA_EXPIRATION_SCHEMA = z.infer<typeof VISA_EXPIRATION_SCHEMA>;

export const TRUSTED_CONTACT_SCHEMA = z
  .object({
    relationship: z
      .string({
        required_error: "Please specify your relationship with this person",
      })
      .refine(
        (val) => RELATIONSHIP_OPTIONS.includes(val),
        "Please select a valid relationship",
      ),
    customRelationship: z.string().optional(),
    phoneNumber: z
      .string({
        required_error: "Phone is required",
      })
      .regex(
        /^\(?([0-9]{3})\)?[ -.●]?([0-9]{3})[-.●]?([0-9]{4})$/,
        "Please provide a valid phone number",
      ),
  })
  .merge(EMAIL_SCHEMA)
  .merge(NAME_SCHEMA())
  .superRefine((data, ctx) => {
    if (data.relationship === OTHER_RELATIONSHIP && !data.customRelationship) {
      ctx.addIssue({
        path: ["customRelationship"],
        code: z.ZodIssueCode.custom,
        message: "Please specify your relationship",
      });
    }
  });

export type TRUSTED_CONTACT_SCHEMA = z.infer<typeof TRUSTED_CONTACT_SCHEMA>;

export const EMPLOYMENT_SCHEMA = z
  .object({
    employmentStatus: z.enum(
      [
        EmploymentStatus.Employed,
        EmploymentStatus.Retired,
        EmploymentStatus.Unemployed,
        EmploymentStatus.Student,
      ],
      { required_error: "Employment status is required" },
    ),
    employerName: z.string().trim().optional(),
    occupation: z.string().trim().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.employmentStatus === EmploymentStatus.Employed) {
      if (!data.employerName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Employer name is required",
          path: ["employerName"],
        });
      }
      if (!data.occupation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Occupation is required",
          path: ["occupation"],
        });
      }
    }
  });
export type EMPLOYMENT_SCHEMA = z.infer<typeof EMPLOYMENT_SCHEMA>;

export const AFFILIATED_EXCHANGE_OR_FINRA_SCHEMA = z
  .object({
    isAffiliated: z.boolean({
      required_error: "This field is required",
    }),
    entityName: z.string({
      required_error: "This field is required",
    }),
  })
  .superRefine((data, ctx) => {
    if (!data.entityName && data.isAffiliated) {
      ctx.addIssue({
        path: ["entityName"],
        code: z.ZodIssueCode.custom,
        message: "Organization name is required when affiliated",
      });
    }
    if (data.entityName?.length === 0 && data.isAffiliated) {
      ctx.addIssue({
        path: ["entityName"],
        code: z.ZodIssueCode.custom,
        message: "Organization name cannot be empty",
      });
    }
    if (data.entityName.length > 100 && data.isAffiliated) {
      ctx.addIssue({
        path: ["entityName"],
        code: z.ZodIssueCode.custom,
        message: `Organization name must be less than 100 characters`,
      });
    }
  });
export type AFFILIATED_EXCHANGE_OR_FINRA_SCHEMA = z.infer<
  typeof AFFILIATED_EXCHANGE_OR_FINRA_SCHEMA
>;

export const CONTROL_PERSON_SCHEMA = z
  .object({
    controlPerson: z.boolean(),
    companySymbols: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    if (
      data.controlPerson &&
      (!data.companySymbols || data.companySymbols.length === 0)
    ) {
      ctx.addIssue({
        path: ["companySymbols"],
        code: z.ZodIssueCode.custom,
        message: "Please select the companies you are associated with",
      });
    }
  });
export type CONTROL_PERSON_SCHEMA = z.infer<typeof CONTROL_PERSON_SCHEMA>;

export const POLITICALLY_EXPOSED_SCHEMA = z
  .object({
    politicallyExposed: z.boolean(),
    politicalOrganization: z.string().optional(),
    politicalTitle: z.string().optional(),
    immediateFamily: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.politicallyExposed && !data.politicalOrganization) {
      ctx.addIssue({
        path: ["politicalOrganization"],
        code: z.ZodIssueCode.custom,
        message: "Please specify the political organization",
      });
    }
    if (data.politicallyExposed && !data.immediateFamily) {
      ctx.addIssue({
        path: ["immediateFamily"],
        code: z.ZodIssueCode.custom,
        message: "Please specify the family member name",
      });
    }
  });
export type POLITICALLY_EXPOSED_SCHEMA = z.infer<
  typeof POLITICALLY_EXPOSED_SCHEMA
>;

export const POLITICALLY_EXPOSED_BUSINESS_SCHEMA =
  POLITICALLY_EXPOSED_SCHEMA.superRefine((data, ctx) => {
    if (data.politicallyExposed && !data.politicalTitle) {
      ctx.addIssue({
        path: ["politicalTitle"],
        code: z.ZodIssueCode.custom,
        message: "Please specify the political title",
      });
    }
  });
export type POLITICALLY_EXPOSED_BUSINESS_SCHEMA = z.infer<
  typeof POLITICALLY_EXPOSED_BUSINESS_SCHEMA
>;

export const ADDITIONAL_DOCUMENTS_SCHEMA = (requiredTypes: CipCategory[]) => {
  return z.object({
    [CipCategory.Address]: z.object({
      file: z
        .string()
        .refine(
          (file) => !requiredTypes.includes(CipCategory.Address) || !!file,
          {
            message: `${
              CIP_CATEGORY_NAME_MAP[CipCategory.Address]
            } is required`,
          },
        ),
      fileName: z.string(),
      loading: z.boolean(),
      id: z.string().optional(),
    }),
    [CipCategory.Dob]: z.object({
      file: z
        .string()
        .refine((file) => !requiredTypes.includes(CipCategory.Dob) || !!file, {
          message: `${CIP_CATEGORY_NAME_MAP[CipCategory.Dob]} is required`,
        }),
      fileName: z.string(),
      loading: z.boolean(),
      id: z.string().optional(),
    }),
    [CipCategory.TaxId]: z.object({
      file: z
        .string()
        .refine(
          (file) => !requiredTypes.includes(CipCategory.TaxId) || !!file,
          {
            message: `${CIP_CATEGORY_NAME_MAP[CipCategory.TaxId]} is required`,
          },
        ),
      fileName: z.string(),
      loading: z.boolean(),
      id: z.string().optional(),
    }),
    [CipCategory.Name]: z.object({
      file: z
        .string()
        .refine((file) => !requiredTypes.includes(CipCategory.Name) || !!file, {
          message: `${CIP_CATEGORY_NAME_MAP[CipCategory.Name]} is required`,
        }),
      fileName: z.string(),
      loading: z.boolean(),
      id: z.string().optional(),
    }),
  });
};
