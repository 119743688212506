import { useCallback, useState } from "react";

import { useKeyValueStore } from "./useKeyValueStore";

export enum HintStatus {
  Active = "Active",
  Dismissed = "Dismissed",
}

export type DismissedHintsSavedState = {
  status: HintStatus;
};

export const useHint = (args: { hintKey: string; skip?: boolean }) => {
  const { hintKey } = args;
  const [keyGet, keySet] = useKeyValueStore<DismissedHintsSavedState>({
    namespace: "hints",
    keys: new Set([hintKey]),
    skip: args.skip,
  });
  // keyValues aren't immediately updated, so we track the dismissed state locally
  const [dismissed, setDismissed] = useState(false);

  const hintStatus = dismissed
    ? HintStatus.Dismissed
    : (keyGet.data[hintKey]?.status ?? HintStatus.Active);

  const closeHint = useCallback(() => {
    // Dismiss it forever since it's not resurfaceable
    if (hintStatus === HintStatus.Active) {
      keySet([
        {
          // No expiry for dismissed hints
          key: hintKey,
          value: { status: HintStatus.Dismissed },
        },
      ]);
      setDismissed(true);
    }
  }, [keySet, hintStatus, hintKey]);

  return { closeHint, hintStatus, loading: keyGet.loading };
};
