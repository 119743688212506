import { useState } from "react";

import { AllocationAction, CashTransferDirection } from "../generated/graphql";

export const AllocationActionToVerb: Record<AllocationAction, string> = {
  [AllocationAction.Edit]: "edit",
  [AllocationAction.Remove]: "remove",
  [AllocationAction.Deposit]: "deposit to",
  [AllocationAction.Withdraw]: "withdraw from",
  [AllocationAction.Rebalance]: "rebalance",
};

export const CashTransferDirectionToAllocationAction: Record<
  CashTransferDirection,
  AllocationAction
> = {
  [CashTransferDirection.Deposit]: AllocationAction.Deposit,
  [CashTransferDirection.Withdrawal]: AllocationAction.Withdraw,
};

export const useAllocationActionDisabledModal = () => {
  const [disabledAction, setDisabledAction] = useState<AllocationAction>();
  const [opened, setOpened] = useState(false);

  const open = (action: AllocationAction) => {
    setDisabledAction(action);
    setOpened(true);
  };

  const close = () => {
    setOpened(false);
  };

  return {
    disabledAction,
    opened,
    open,
    close,
  };
};
