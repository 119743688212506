import { DividendPreferenceType } from "../generated/graphql";

export type DividendPreferenceOption = {
  label: string;
  value: DividendPreferenceType;
  description: string;
  infoPopOver?: string;
};

export type SelfManagedDividendPreferenceType = Exclude<
  DividendPreferenceType,
  DividendPreferenceType.Reinvest
>;
export type DirectIndexingDividendPreferenceType = Exclude<
  DividendPreferenceType,
  DividendPreferenceType.MoveToDirectIndexing
>;

export const SELF_MANAGED_DIVIDEND_OPTIONS_LABEL: Record<
  SelfManagedDividendPreferenceType,
  string
> = {
  [DividendPreferenceType.MoveToDirectIndexing]:
    "Reinvest into allocation or direct indices",
  [DividendPreferenceType.PayLoanAndFrecCash]:
    "Pay down portfolio line of credit",
  [DividendPreferenceType.MoveToTreasury]: "Reinvest into Treasury",
  [DividendPreferenceType.MoveToFrecCash]: "Move to Cash",
};

export const DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL: Record<
  DirectIndexingDividendPreferenceType,
  string
> = {
  [DividendPreferenceType.Reinvest]:
    "Reinvest into allocation or direct indices",
  [DividendPreferenceType.PayLoanAndFrecCash]:
    "Pay down portfolio line of credit",
  [DividendPreferenceType.MoveToTreasury]: "Reinvest into Treasury",
  [DividendPreferenceType.MoveToFrecCash]: "Move to Cash",
};

export const SELF_MANAGED_DIVIDEND_OPTIONS: DividendPreferenceOption[] = [
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToDirectIndexing
      ],
    value: DividendPreferenceType.MoveToDirectIndexing,
    description:
      "Reinvest dividends equally across your allocation or direct indexing accounts",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.PayLoanAndFrecCash
      ],
    value: DividendPreferenceType.PayLoanAndFrecCash,
    description:
      "Use dividends to pay off any balance on your portfolio line of credit",
    infoPopOver:
      "If your portfolio line of credit is fully paid down, any excess will move to your Cash balance on Frec",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToTreasury
      ],
    value: DividendPreferenceType.MoveToTreasury,
    description: "Earn yield on your dividends by moving them to Treasury",
    infoPopOver:
      "Since there is a $5 minimum requirement to invest into Treasury, any dividends less than $5 will accumulate in “held in process” under Treasury until the amount is $5 or more and then will be automatically invested.",
  },
  {
    label:
      SELF_MANAGED_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToFrecCash
      ],
    value: DividendPreferenceType.MoveToFrecCash,
    description: "Move any dividends to your Cash balance on Frec",
  },
];

export const DIRECT_INDEXING_DIVIDEND_OPTIONS: DividendPreferenceOption[] = [
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[DividendPreferenceType.Reinvest],
    value: DividendPreferenceType.Reinvest,
    description:
      "Reinvest dividends back into your allocation or their respective direct index",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.PayLoanAndFrecCash
      ],
    value: DividendPreferenceType.PayLoanAndFrecCash,
    description:
      "Use dividends to pay off any balance on your portfolio line of credit",
    infoPopOver:
      "If your portfolio line of credit is fully paid down, any excess will move to your Cash balance on Frec",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToTreasury
      ],
    value: DividendPreferenceType.MoveToTreasury,
    description: "Earn yield on your dividends by moving them to Treasury",
    infoPopOver:
      "Since there is a $5 minimum requirement to invest into Treasury, any dividends less than $5 will accumulate in “held in process” under Treasury until the amount is $5 or more and then will be automatically invested.",
  },
  {
    label:
      DIRECT_INDEXING_DIVIDEND_OPTIONS_LABEL[
        DividendPreferenceType.MoveToFrecCash
      ],
    value: DividendPreferenceType.MoveToFrecCash,
    description: "Move any dividends to your Cash balance on Frec",
  },
];
