export const classNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const mergeClassNames = (
  originalClassNames: Record<string, string>,
  newClassNames?: Record<string, string>,
) => {
  const results = { ...originalClassNames, ...newClassNames };
  const keysUnion = Object.keys(results);
  keysUnion.forEach((key) => {
    const originalValue = originalClassNames[key];
    const newValue = newClassNames?.[key];
    results[key] = [originalValue, newValue].filter(Boolean).join(" ");
  });
  return results;
};
