import {
  convertAllocationConfigCashTransfersToInput,
  normalizeAllocationConfig,
  validateAllocationConfig,
  ValidateAllocationConfigResult,
} from "@frec-js/common";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AllocationConfigCashTransferInput,
  AllocationConfigFragment,
  AllocationConfigPurchaseOrderInput,
  AllocationConfigType,
} from "../../generated/graphql";
import {
  CurrentSubAccountAllocationsMap,
  useCurrentSubAccountAllocations,
} from "../../hooks/useCurrentSubAccountAllocations";

export interface AllocationEditProviderContextProps {
  loading: boolean;
  // SetupLayout
  madeChanges: boolean;
  // Allocation
  currentSubAccountAllocations: CurrentSubAccountAllocationsMap;
  cashTransfers: AllocationConfigCashTransferInput[];
  addCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  removeCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  editCashTransfer: (cashTransfer: AllocationConfigCashTransferInput) => void;
  // TODO: add purchase orders
  purchaseOrders: AllocationConfigPurchaseOrderInput[];
  // Utils
  normalize: () => void;
  // Validation
  validationErrors: ValidateAllocationConfigResult;
}

const AllocationEditContext = createContext<AllocationEditProviderContextProps>(
  {
    loading: false,
    // SetupLayout
    madeChanges: false,
    // Allocation
    currentSubAccountAllocations: new Map(), // Used for quick lookup of current allocation percentages
    cashTransfers: [],
    addCashTransfer: () => undefined,
    removeCashTransfer: () => undefined,
    editCashTransfer: () => undefined,
    // TODO: add purchase orders
    purchaseOrders: [],
    // Utils
    normalize: () => undefined,
    // Validation
    validationErrors: {
      allocationConfigErrors: [],
    },
  },
);

export const useAllocationEdit = () => {
  const context = useContext<AllocationEditProviderContextProps>(
    AllocationEditContext,
  );
  return context;
};

type AllocationEditProviderProps = PropsWithChildren<{
  clearingAccountId?: string;
  allocationConfig: AllocationConfigFragment;
}>;

export const AllocationEditProvider = ({
  clearingAccountId,
  allocationConfig,
  children,
}: AllocationEditProviderProps) => {
  const prefillPortfolioAllocation =
    useRef<AllocationConfigCashTransferInput[]>();
  // Fetch portfolio aggregates for the clearing account to calculate
  // the current allocation percentages for each sub account.
  const { currentSubAccountAllocations, loading } =
    useCurrentSubAccountAllocations(clearingAccountId);

  const cashTransfersFromConfig = useMemo(() => {
    return convertAllocationConfigCashTransfersToInput(
      allocationConfig.cashTransfers,
    );
  }, [allocationConfig.cashTransfers]);

  // States
  const [cashTransfers, setCashTransfers] = useState<
    AllocationConfigCashTransferInput[]
  >(cashTransfersFromConfig);
  // TODO: add purchase orders
  const [purchaseOrders, _setPurchaseOrders] = useState<
    AllocationConfigPurchaseOrderInput[]
  >([]);

  // Initialize cash transfers from sub account allocations
  useEffect(() => {
    if (
      loading ||
      prefillPortfolioAllocation.current ||
      currentSubAccountAllocations.size === 0
    ) {
      return;
    }
    const initialCashTransfers: AllocationConfigCashTransferInput[] = [];
    // Only include sub accounts that have greater than 0% allocation, because
    // we only use integers for the percentage, we are filtering out small sub accounts.
    currentSubAccountAllocations.forEach((percentage, subAccountId) => {
      initialCashTransfers.push({
        allocationSubAccountId: subAccountId,
        percentage: percentage,
      });
    });
    // Normalize the cash transfers to ensure they sum to 100%
    const normalizedCashTransfers = normalizeAllocationConfig(
      {
        cashTransfers: initialCashTransfers,
        purchaseOrders: [],
        type: AllocationConfigType.PortfolioRebalance,
      },
      0,
    ).cashTransfers;
    prefillPortfolioAllocation.current = normalizedCashTransfers;
  }, [currentSubAccountAllocations, loading]);

  // We could be using xor to determine if there are changes, but it's
  // a lot of overhead (especially since we'd have to convert Decimals to primitives)
  // for a pretty inconsequential feature.
  // Instead, we'll just use a boolean flag to determine if there are changes.
  const [madeChanges, setMadeChanges] = useState(false);

  // TODO: replace with reducer
  const addCashTransfer = useCallback(
    (cashTransfer: AllocationConfigCashTransferInput) => {
      const currentPercentage = prefillPortfolioAllocation.current?.find(
        (c) => c.allocationSubAccountId === cashTransfer.allocationSubAccountId,
      )?.percentage;
      if (currentPercentage?.gt(0)) {
        setCashTransfers((prev) => [
          ...prev,
          { ...cashTransfer, percentage: currentPercentage.toDP(0) },
        ]);
      } else {
        setCashTransfers((prev) => [...prev, cashTransfer]);
      }
      setMadeChanges(true);
    },
    [currentSubAccountAllocations],
  );
  const removeCashTransfer = useCallback(
    (cashTransfer: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.filter(
          (ct) =>
            ct.allocationSubAccountId !== cashTransfer.allocationSubAccountId,
        ),
      );
      setMadeChanges(true);
    },
    [],
  );
  const editCashTransfer = useCallback(
    (cashTransfer: AllocationConfigCashTransferInput) => {
      setCashTransfers((prev) =>
        prev.map((ct) =>
          ct.allocationSubAccountId === cashTransfer.allocationSubAccountId
            ? cashTransfer
            : ct,
        ),
      );
      setMadeChanges(true);
    },
    [],
  );

  const normalize = useCallback(() => {
    const normalized = normalizeAllocationConfig(
      {
        type: allocationConfig.type,
        cashTransfers,
        // TODO: implement purchase orders
        purchaseOrders: [],
      },
      0,
    );
    setCashTransfers(normalized.cashTransfers);
    setMadeChanges(true);
  }, [allocationConfig.type, cashTransfers]);

  const validationErrors = useMemo(() => {
    const errors = validateAllocationConfig({
      type: allocationConfig.type,
      cashTransfers,
      purchaseOrders: [],
    });
    return errors;
  }, [allocationConfig.type, cashTransfers]);

  const value = useMemo<AllocationEditProviderContextProps>(() => {
    return {
      loading,
      // SetupLayout
      madeChanges,
      // Allocation
      currentSubAccountAllocations,
      cashTransfers,
      addCashTransfer,
      removeCashTransfer,
      editCashTransfer,
      // TODO: add purchase orders
      purchaseOrders,
      // Utils
      normalize,
      // Validation
      validationErrors,
    };
  }, [
    loading,
    madeChanges,
    currentSubAccountAllocations,
    cashTransfers,
    addCashTransfer,
    removeCashTransfer,
    editCashTransfer,
    purchaseOrders,
    normalize,
    validationErrors,
  ]);

  return (
    <AllocationEditContext.Provider value={value}>
      {children}
    </AllocationEditContext.Provider>
  );
};
