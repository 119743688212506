import {
  CIP_CATEGORY_DESCRIPTION_MAP,
  CIP_CATEGORY_NAME_MAP,
  DateFormatPreset,
  formatDateWithPreset,
} from "@frec-js/common";

import {
  AuthUserFragment,
  CipCategory,
  CipInvestigationCategory,
  UploadDocumentType,
} from "../../generated/graphql";

export type AdditionalDocumentsFormType = Record<
  CipCategory,
  { id?: string; file: File | string; fileName: string; loading: boolean }
>;

export const toAdditionalDocumentsForm = (
  user: AuthUserFragment | null,
): AdditionalDocumentsFormType => {
  const addressVerificationDoc = user?.uploadedDocuments?.find(
    ({ type }) => type === UploadDocumentType.CipInvestigationAddress,
  );
  const dobVerificationDoc = user?.uploadedDocuments?.find(
    ({ type }) => type === UploadDocumentType.CipInvestigationDob,
  );
  const nameVerificationDoc = user?.uploadedDocuments?.find(
    ({ type }) => type === UploadDocumentType.CipInvestigationName,
  );
  const taxIdVerificationDoc = user?.uploadedDocuments?.find(
    ({ type }) => type === UploadDocumentType.CipInvestigationTaxId,
  );
  return {
    [CipCategory.Address]: addressVerificationDoc
      ? {
          id: addressVerificationDoc.id,
          file: addressVerificationDoc.location,
          fileName: addressVerificationDoc.originalName,
          loading: false,
        }
      : { file: "", fileName: "", loading: false },
    [CipCategory.Dob]: dobVerificationDoc
      ? {
          id: dobVerificationDoc.id,
          file: dobVerificationDoc.location,
          fileName: dobVerificationDoc.originalName,
          loading: false,
        }
      : { file: "", fileName: "", loading: false },
    [CipCategory.Name]: nameVerificationDoc
      ? {
          id: nameVerificationDoc.id,
          file: nameVerificationDoc.location,
          fileName: nameVerificationDoc.originalName,
          loading: false,
        }
      : { file: "", fileName: "", loading: false },
    [CipCategory.TaxId]: taxIdVerificationDoc
      ? {
          id: taxIdVerificationDoc.id,
          file: taxIdVerificationDoc.location,
          fileName: taxIdVerificationDoc.originalName,
          loading: false,
        }
      : { file: "", fileName: "", loading: false },
  };
};

export type AdditionalDocumentsListItem = {
  title: string;
  description: string;
  type: CipCategory;
  userInfo: string;
};

export const mapAdditionalDocuments = (
  user: AuthUserFragment | null,
  requestedDocuments: CipInvestigationCategory[],
): AdditionalDocumentsListItem[] => {
  return requestedDocuments.map((category) => {
    const type = category.name;
    const description =
      CIP_CATEGORY_DESCRIPTION_MAP[type] ??
      category.requestedDocuments?.map((doc) => doc.description).join(", ") ??
      "";
    return {
      title: CIP_CATEGORY_NAME_MAP[type],
      description,
      type,
      userInfo: getUserInfoForCipCategory[type](user),
    };
  });
};

// Functions to extract information from an User object for each CipCategory.
export const getUserInfoForCipCategory: Record<
  CipCategory,
  (user: AuthUserFragment | null) => string
> = {
  [CipCategory.Address]: (user) => {
    const address = user?.userAddresses?.[0];
    if (!address) return "";
    const { addressLine1, addressLine2, city, state, zipCode } = address;
    return (
      addressLine1 +
      (addressLine2 ? `, ${addressLine2}` : "") +
      ` ${city}, ${state} ${zipCode}`
    );
  },
  [CipCategory.Name]: (user) => {
    const personalDetails = user?.userPersonalDetails;
    if (!personalDetails) return "";
    return personalDetails.fullName;
  },
  [CipCategory.Dob]: (user) => {
    const identityDetails = user?.userIdentityDetails;
    if (!identityDetails) return "";
    return formatDateWithPreset(
      identityDetails.dateOfBirth,
      DateFormatPreset.DATE_FULL_MONTH_YEAR,
    );
  },
  [CipCategory.TaxId]: () => {
    // Don't return anything for TaxId since we don't want to show their SSN.
    return "";
  },
};
